export const read = (key) => {
  const value = window.localStorage.getItem(key);
  return typeof value !== 'string' ? '' : value;
};

export const write = (key, val) => {
  if (val !== undefined) window.localStorage.setItem(key, val);
};

export const remove = (key, val) => window.localStorage.removeItem(key, val);

export const sessionRead = (key) => {
  const value = window.sessionStorage.getItem(key);
  return typeof value !== 'string' ? '' : value;
};

export const sessionWrite = (key, val) => {
  if (val !== undefined) window.sessionStorage.setItem(key, val);
};

export const sessionRemove = (key, val) => window.sessionStorage.removeItem(key, val);

// export const clearAll = window.localStorage.clear;
