import { sessionRead } from '../utils/storage';
import { writeToken, clearTokens } from '../utils/jwt';
import { prepareIdps } from '../utils/caseTransforms';
import {
  getHost,
  appUrl,
  fromParams,
  // mocked,
  mockedAsync,
  hiddenFormSubmitter,
  fetchCheckedJson,
} from '../utils/url';
import { loginOptionsMock, getTokenMockResponse } from './mocks';

/* ****************************************************
    URLs
*/

// getValidFields :: * -> {string}
const getValidFields = () => ({
  client_id: getHost(),
  redirect_uri: appUrl('/login'),
});

const getTokenUrl = (code = fromParams('code')) =>
  appUrl('/api/oauthservice/token', {
    ...getValidFields(),
    grant_type: 'authorization_code',
    code,
  });

const refreshTokenUrl = (token) =>
  appUrl('/api/oauthservice/token', {
    ...getValidFields(),
    grant_type: 'refresh_token',
    refresh_token: token,
  });

const loginOptionsUrl = () => appUrl('/api/oauthservice/saml/displayable-identity-providers');
const getAuthInitUrl = () =>
  appUrl(`/api/oauthservice/authorize/initiate`, {
    ...getValidFields(),
    response_type: 'code',
  });

/* ****************************************************
    Auth related requests
*/

export const getEmailVerificationIdpEntityId = async () => {
  const entityId = sessionRead('email-verification-idp-entity-id');
  const optional = sessionRead('email-verification-optional');
  if (entityId)
    return {
      'email-verification-idp-entity-id': entityId,
      'email-verification-optional': optional === 'true',
    };
  return Promise.reject(Error(''));
};

/**
 * If we just got back from IDP login (code in url params),
 * Then fetch token using that code and return,
 * Else If we already have a cached token, then return it looking like a response,
 * Else reject this promise in order to get routed to login page.
 *
 * When testing this in dev mode (see login modes comment below) use "?code=1" in URL to simulate IDP redirect.
 * Also, use getTokenMockResponse('verify') to simulate a token with email-verification-idp-entity-id: true.
 * @returns {Promise} Response object with access_token.
 */
export const getToken = async () => {
  const code = fromParams('code');

  /* ---CONTROL DIFFERENT LOGIN MODES HERE--- */
  // const mock = getTokenMockResponse();
  const mock = getTokenMockResponse('verify');
  /* ------ */

  const liveFn = () => fetchCheckedJson(getTokenUrl(code));

  if (code) return mockedAsync(mock, liveFn).then(writeToken);

  const token = sessionRead('access_token');
  const emailVerification = await getEmailVerificationIdpEntityId();
  if (token) return { access_token: token, ...emailVerification };
  return Promise.reject(Error(''));
};

// refreshToken :: * -> Promise response
export const refreshToken = () => {
  const mock = getTokenMockResponse();
  const liveFn = () =>
    fetchCheckedJson(refreshTokenUrl(sessionRead('refresh_token'))).then(writeToken);
  return mockedAsync(mock, liveFn).catch(clearTokens);
};

// getIdentityProviders :: * -> Promise [{}...]
export const getIdentityProviders = () => {
  const mock = loginOptionsMock;
  const liveFn = () => fetchCheckedJson(loginOptionsUrl());
  return mockedAsync(mock, liveFn).then(prepareIdps);
};

export const postLogin = async (idpUrl) => {
  const mockFn = () => {
    writeToken(getTokenMockResponse());
    window.location = appUrl('/');
  };
  const liveFn = async () => {
    const tokenForEmailVerifOrEmpty = sessionRead('access_token') || '';
    const headers = new Headers({
      'idp-entity-id': idpUrl,
      'access-token': tokenForEmailVerifOrEmpty,
    });
    const response = await fetchCheckedJson(getAuthInitUrl(), { mode: 'same-origin', headers });
    hiddenFormSubmitter(
      { action: response.IdpURL },
      { RelayState: response.RelayState, SAMLRequest: response.SAMLRequest },
    );
    return response;
  };
  return mockedAsync(mockFn, liveFn);
};
