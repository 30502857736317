import { createContext } from 'react';
import { Machine, assign } from 'xstate';
import * as R from 'ramda';

import { clearTokens, isSender, getJwtEmail } from '../utils/jwt';
import { fromParams, appUrl } from '../utils/url';
import { getIdentityProviders, getToken, postLogin } from './authAPI';
import { deleteCase, fetchCases, getAuthorConnectedCases, rejectCase, revokeCase } from './appAPI';
import { sessionRead, sessionWrite } from '../utils/storage';

export const AppMachineContext = createContext();

export const machineConf = {
  id: 'app',
  initial: 'checking',
  context: {
    // profile: {},
    cases: [],
    delegatedCases: [],
    signIdps: [],
    authIdps: [],
    verificationUrl: undefined,
    verificationOptional: undefined,
    verificationDisabled: undefined,
    loginMessage: undefined,
    errorMsg: undefined,
  },
  states: {
    checking: {
      invoke: {
        src: 'checkToken',
        onError: { target: 'gettingIdps', actions: 'setLoginMessage' },
        onDone: [
          {
            cond: 'hasEmailVerification',
            target: 'verifyEmail',
          },
          { target: 'authenticated', actions: 'clearCodeParam' },
        ],
      },
    },
    verifyEmail: {
      entry: 'setVerificationUrl',
      on: {
        LOG_OUT: '#app.gettingIdps',
        LOG_IN: 'loggingIn',
        CONTINUE: { target: 'authenticated', actions: 'redirectToUrl' },
      },
    },
    gettingIdps: {
      invoke: {
        src: 'getIdps',
        onError: 'login',
        onDone: { target: 'login', actions: 'setIdps' },
      },
    },
    login: {
      entry: ['setLoginMessage', 'clearTokens'],
      on: { LOG_IN: 'loggingIn' },
    },
    redirecting: {
      on: { CHECKING: 'checking' },
    },
    loggingIn: {
      invoke: {
        src: 'logIn',
        onError: 'login',
        onDone: 'redirecting',
      },
    },
    authenticated: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            INIT_DOCUMENTS: 'fetchingCases',
            LOG_OUT: '#app.gettingIdps',
            // FETCH_IDPS: 'fetchingIdps',
            REVOKE_DOCUMENT: 'revoking',
            REJECT_DOCUMENT: 'rejecting',
            DELETE_DOCUMENT: 'deleting',
            SET_IDPS: { actions: 'setIdps' },
          },
        },
        revoking: {
          invoke: {
            src: 'revokeDoc',
            onError: { target: 'idle', actions: 'setErrorMsg' },
            onDone: 'fetchingCases',
          },
        },
        rejecting: {
          invoke: {
            src: 'rejectDoc',
            onError: { target: 'idle', actions: 'setErrorMsg' },
            onDone: 'fetchingCases',
          },
        },
        deleting: {
          invoke: {
            src: 'deleteDoc',
            onError: { target: 'idle', actions: 'setErrorMsg' },
            onDone: { target: 'idle', actions: 'removeCase' },
            // onDone: 'fetchingCases',
          },
        },
        fetchingCases: {
          entry: 'resetErrorMsg',
          invoke: {
            src: 'fetchCases',
            onError: 'fetchFailed',
            onDone: { actions: 'setCases', target: 'idle' },
          },
        },
        // idpsFetchFailed: {
        //   // This state can be used e.g. to show a retry button
        //   on: { RETRY: 'fetchingIdps', LOG_OUT: '#app.gettingIdps' },
        // },
        fetchFailed: {
          // This state can be used e.g. to show a retry button
          on: { RETRY: 'fetchingCases', LOG_OUT: '#app.gettingIdps' },
        },
      },
    },
  },
};

export const appMachine = Machine(machineConf, {
  services: {
    checkToken: () => getToken(),
    getIdps: () => getIdentityProviders(),
    logIn: (ctx, event) => postLogin(event.url),
    fetchCases: () => Promise.all([fetchCases(), isSender() ? getAuthorConnectedCases() : []]),
    revokeDoc: (ctx, { id, message }) => revokeCase(id, message),
    rejectDoc: (ctx, { id, message }) => rejectCase(id, message),
    deleteDoc: (ctx, { id }) => deleteCase(id),
  },
  guards: {
    hasEmailVerification: (ctx, { data }) => {
      const hasVerifiedEmail = sessionRead('verified_email') === 'true';
      const email = getJwtEmail();
      if (hasVerifiedEmail && email.length && email[0] !== '') {
        return undefined;
      }
      return data?.[['email-verification-idp-entity-id']];
    },
  },
  actions: {
    setCases: assign({
      cases: (ctx, { data = [[], []] }) => data[0],
      delegatedCases: (ctx, { data = [[], []] }) => data[1],
    }),
    removeCase: assign({
      cases: ({ cases }, { data: { ID: removedId } = {} }) =>
        R.filter(({ ID }) => ID !== removedId)(cases),
      delegatedCases: ({ delegatedCases }, { data: { ID: removedId } = {} }) =>
        R.filter(({ ID }) => ID !== removedId)(delegatedCases),
    }),
    setVerificationUrl: assign({
      verificationUrl: (ctx, { data }) => data?.['email-verification-idp-entity-id'],
      verificationOptional: (ctx, { data }) => data?.['email-verification-optional'],
      verificationDisabled: (ctx, { data }) => data?.['email-verification-disabled'],
    }),
    setIdps: assign((ctx, { data: { signIdps, authIdps } = {} }) => ({ signIdps, authIdps })),
    setLoginMessage: assign({
      loginMessage: (ctx, { type, data = '' }) => (type === 'error.execution' ? data : ''),
    }),
    setErrorMsg: assign({
      errorMsg: (ctx, { type, data = '' }) =>
        type === 'error.execution'
          ? data
          : type === 'error.platform.revokeDoc' || type === 'error.platform.rejectDoc'
          ? data.message
          : '',
    }),
    resetErrorMsg: assign({ errorMsg: () => '' }),
    clearTokens: () => clearTokens(),
    clearCodeParam: () => {
      if (fromParams('code')) window.location = appUrl(window.location.pathname);
    },
    redirectToUrl: () => {
      sessionWrite('verified_email', true);
      const toUrl = sessionRead('toURL');
      if (toUrl && toUrl !== '/login') window.location = appUrl(toUrl);
      else if (fromParams('code')) window.location = appUrl(window.location.pathname);
      sessionStorage.removeItem('toURL');
    },
  },
});
