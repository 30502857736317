import React, { useState } from 'react';
import { without } from 'ramda';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

import { langNames } from '../i18n/lang';

export const LangMenu = ({ className, ...rest }) => {
  const { i18n, t } = useTranslation();
  const [dropdownAnchor, setAnchorEl] = useState();
  const dropdownOpen = Boolean(dropdownAnchor);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl();

  const languages = without([i18n.language.split('-')[0]])(Object.keys(i18n.store.data));

  const changeLang = (lang) => () => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <div className={className}>
      <Tooltip title={t('Languages')}>
        <IconButton
          aria-label={t('available languages')}
          aria-controls="appbar-menu-lang"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <LanguageIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="appbar-menu-lang"
        anchorEl={dropdownAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={dropdownOpen}
        onClose={handleClose}
        {...rest}
      >
        {languages.map((lang) => (
          <MenuItem key={lang} onClick={changeLang(lang)}>
            {langNames[lang]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
