import React, { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    marginBottom: '1em',
  },
  subtitle: {
    padding: theme.spacing(2, 0, 1, 0),
  },
}));

const selectMenuProps = {
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
};

export const DropDownSelect = ({
  items = [],
  register,
  setValue,
  label = '',
  refName = '',
  defaultValue = null,
  noLabel = false,
  disabled = false,
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(null);
  useEffect(() => {
    if (defaultValue) {
      setValue(refName, defaultValue);
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);
  return items.length ? (
    <>
      {!noLabel && (
        <InputLabel htmlFor="date" className={classes.subtitle}>
          {label}
        </InputLabel>
      )}
      <TextField
        select
        fullWidth
        disabled={disabled}
        SelectProps={selectMenuProps}
        inputRef={register({
          name: refName,
        })}
        onChange={(e) => {
          setValue(refName, e.target.value, true);
          setSelectedValue(e.target.value);
        }}
        defaultValue={defaultValue}
        value={selectedValue}
        className={classes.select}
      >
        {items.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>
    </>
  ) : null;
};
