import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Tooltip } from '@material-ui/core';

export const ChangeOrderButton = ({
  direction = 'down',
  onClick = Function.prototype,
  ariaLabel = '',
}) => (
  <Tooltip title={ariaLabel}>
    <IconButton onClick={onClick} aria-label={ariaLabel}>
      {direction === 'down' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
    </IconButton>
  </Tooltip>
);
