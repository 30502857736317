import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  message: {
    color: theme.palette.warning.main,
  },
}));

export const WarningMessage = ({ message, namespace = 'translations', translate = true }) => {
  const { t } = useTranslation(['translations', 'errors']);
  const classes = useStyles();
  return (
    <Typography className={classes.message} variant="body2">
      {translate ? t(message, { ns: namespace }) : message}
    </Typography>
  );
};
