import React, { useMemo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useMachine } from '@xstate/react';
import { pdfjs } from 'react-pdf';
// Importing directly from pdfjs-dist might cause version errors
import pdfjsWorker from 'react-pdf/dist/esm/pdf.worker.entry';
import { useTranslation } from 'react-i18next';
import { sessionWrite, sessionRead } from './utils/storage';
// import { inspect } from '@xstate/inspect';

import './i18n';
import {
  useDrawerState,
  useDarkState,
  DrawerContext,
  DarkContext,
  SkinContext,
  useSkinState,
} from './store/appContext';
import { AppMachineContext, appMachine } from './store/appMachine';
import { AuthGuard } from './ui/AuthGuard';
import { theme as customTheme } from './utils/theme';

// inspect({
//   url: 'https://statecharts.io/inspect',
//   iframe: false,
// });

// favicon
if (customTheme.faviconUrl) {
  document.getElementById('favicon').setAttribute('href', `/${customTheme.faviconUrl}`);
}
// Website title
if (customTheme.websiteTitle) {
  document.getElementsByTagName('title')[0].text = customTheme.websiteTitle;
}

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const MachineProviders = ({ children }) => {
  const appMachineInstance = useMachine(appMachine /* , { devTools: true } */);

  /* Debug appmachineInstance state
  useEffect(() => {
    // Log state on each transition
    const [
      {
        value,
        // context: { cases, ...ctx },
      },
    ] = appMachineInstance;
    // const ctxLog = { ...ctx, cases: cases.length ? ['<LOTS_OF_DATA>'] : cases };

    // console.log('appMachineInstance:', JSON.stringify(value)); // , JSON.stringify(ctxLog, null, 2)
  }, [appMachineInstance]);
  */

  return (
    <AppMachineContext.Provider value={appMachineInstance}>{children}</AppMachineContext.Provider>
  );
};

export const App = () => {
  const { drawerOpen, setDrawer } = useDrawerState();
  const { isDark, setDark } = useDarkState();
  const { path, setPath } = useSkinState();
  const { i18n, t } = useTranslation();

  // Set temporary redirect-url
  if (window.location.pathname && window.location.pathname !== '/') {
    if (!sessionRead('toURL')) sessionWrite('toURL', window.location.pathname);
  }

  const theme = useMemo(() => {
    const isEngLanguage = i18n.language.split('-')[0] === 'en';
    const lightLogo = isEngLanguage && customTheme.enLogo ? customTheme.enLogo : customTheme.logo;
    const darkLogo =
      isEngLanguage && customTheme.enLogoDark ? customTheme.enLogoDark : customTheme.logoDark;

    const themeConf = {
      ...customTheme,
      palette: isDark
        ? { ...customTheme.darkPalette, type: 'dark' }
        : { ...customTheme.palette, type: 'light' },
      logo: {
        content: isDark && darkLogo ? lightLogo : darkLogo,
      },
      loginBackground:
        isDark && customTheme.loginBackgroundDark
          ? customTheme.loginBackgroundDark
          : customTheme.loginBackground,
    };
    const newTheme = createMuiTheme(themeConf);
    return newTheme;
  }, [isDark, customTheme, t]);

  const drawerCtx = useMemo(() => ({ drawerOpen, setDrawer }), [drawerOpen, setDrawer]);
  const darkCtx = useMemo(() => ({ isDark, setDark }), [isDark, setDark]);
  const skinCtx = useMemo(() => ({ path, setPath }), [path, setPath]);
  return (
    <React.StrictMode>
      <DarkContext.Provider value={darkCtx}>
        <DrawerContext.Provider value={drawerCtx}>
          <SkinContext.Provider value={skinCtx}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <MachineProviders>
                  <AuthGuard />
                </MachineProviders>
              </BrowserRouter>
            </ThemeProvider>
          </SkinContext.Provider>
        </DrawerContext.Provider>
      </DarkContext.Provider>
    </React.StrictMode>
  );
};
