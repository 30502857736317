import React from 'react';
import { useTranslation } from 'react-i18next';

import { WarningMessage } from '../ui/WarningMessage';

import { getEmail } from './jwt';

const getDomain = (email) => email && email.split('@')[1];

export const DomainWarningMessage = ({ email }) => {
  const { t } = useTranslation();

  const emailDomain = getDomain(email);
  const userDomain = getDomain(getEmail()[0]);
  const domainWarning = email && userDomain !== emailDomain;

  return (
    <>
      {domainWarning && (
        <WarningMessage
          message={`
                ${t(
                  'The provided email address does not have the same domain as your email address',
                )}
                (@${userDomain}).
                ${t(
                  'A user with an email address outside your organization might not be able to use the service as an administrator',
                )}.`}
          translate={false}
        />
      )}
    </>
  );
};
