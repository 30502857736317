import { IconButton, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppMachineContext } from '../store/appMachine';
import { write, sessionRemove } from '../utils/storage';

const logoutLabel = {
  position: 'absolute',
  fontSize: '0.6em',
  marginBottom: '-3px',
  bottom: 0,
  whiteSpace: 'nowrap',
};

const useStyles = makeStyles((theme) => ({
  menuButton: {
    borderTop: '1px solid rgba(0,0,0,0.15)',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.focus,
    },
  },
}));

export const LogoutButton = ({ variant = 'defaut' }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [, send] = useContext(AppMachineContext);

  const history = useHistory();

  const logout = () => {
    history.push('/');
    write('logOut', 'true');
    sessionRemove('toURL');
    send('LOG_OUT');
  };
  if (variant === 'drawer') {
    return (
      <ListItem className={classes.menuButton} onClick={logout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={t('Log out')} />
      </ListItem>
    );
  }

  return (
    <IconButton aria-label="Logout" aria-haspopup="false" onClick={logout} color="inherit">
      <LogoutIcon />
      <div style={logoutLabel}>{t('Log out')}</div>
    </IconButton>
  );
};
