import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { format } from 'date-fns/fp';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEmail } from '../../../utils/jwt';
import { emailRegex } from '../../../utils/regex';
import { DomainWarningMessage } from '../../../utils/userDomain';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: { padding: theme.spacing(1) },
  },
  subtitle: {
    padding: theme.spacing(2, 0, 1, 0),
    textWrap: 'nowrap',
  },
  delegateContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  emailConnection: {
    flex: '2',
  },
  expirationDate: {
    flex: '1',
  },
  emailTextField: {
    width: '80%',
    minWidth: '300px',
  },
}));

export const AddConnection = ({ onSubmit, errorMessage, connections }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const [inputEmailAddress, setInputEmailAddress] = useState('');
  const [inputExpirationDate, setExpirationDate] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [connectedErrorMessage, setConnectedErrorMessage] = useState('');
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const isDateValid = () => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    if (inputExpirationDate && new Date(inputExpirationDate) >= currentDate) return true;
    setDateErrorMessage('The expiration date must be from today');
    return false;
  };

  const isEmail = () => {
    if (String(inputEmailAddress).match(emailRegex)) return true;
    setFormErrorMessage(inputEmailAddress.length ? 'Invalid email' : 'This field is required');
    return false;
  };

  const isCurrentUser = () => {
    if (getEmail().includes(inputEmailAddress)) {
      setFormErrorMessage('Same user error');
      return true;
    }
    return false;
  };

  const shouldSubmitAuthorConnection = () => {
    const existingConnection = connections.find((con) => con.ConnectedEmail === inputEmailAddress);
    // If there is no existing AuthorConnection
    // If the new expirationDate is not equal to existing expiration date
    // Create new (or update existing) AuthorConnection
    if (
      !existingConnection ||
      (inputExpirationDate &&
        inputExpirationDate !== existingConnection.ExpirationDate?.split('T')[0])
    ) {
      return true;
    }
    // If the expiration date is empty
    // If the input expiration date is equal to existing AuthorConnection's expiration date
    // Do not create new (or update existing) AuthorConnection
    setConnectedErrorMessage('This connection already exists');
    return false;
  };

  const validateInput = () => {
    const isValidEmail = isEmail();
    const isValidDate = !inputExpirationDate || isDateValid();
    const isValidUser = !isCurrentUser();
    return isValidEmail && isValidDate && isValidUser;
  };

  const submit = (e) => {
    e.preventDefault();
    setConnectedErrorMessage('');
    if (validateInput() && shouldSubmitAuthorConnection()) {
      openDialog();
    }
  };

  const onChange = (e) => {
    setInputEmailAddress(e.target.value.toLowerCase());
    if (formErrorMessage) setFormErrorMessage('');
  };

  const onConfirm = () => {
    onSubmit(inputEmailAddress.toLowerCase(), inputExpirationDate);
    setInputEmailAddress('');
    setExpirationDate('');
    closeDialog();
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('Delegate my authority as administrator to')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {inputEmailAddress}
          </DialogContentText>
          <DomainWarningMessage email={inputEmailAddress} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            {t('Cancel')}
          </Button>
          <Button onClick={onConfirm} color="secondary" autoFocus variant="contained">
            {t('Delegate')}
          </Button>
        </DialogActions>
      </Dialog>
      <form className={classes.form} noValidate onSubmit={submit}>
        <Typography variant="subtitle1" className={classes.uppercase}>
          {t('Delegate my authority as administrator to')}
        </Typography>
        <Divider />
        <div className={classes.delegateContainer}>
          <div className={classes.emailConnection}>
            <InputLabel
              htmlFor="AddEmailConnection"
              id="AddEmailConnection"
              className={classes.subtitle}
              required
            >
              {t('Email')}
            </InputLabel>
            <TextField
              error={!!formErrorMessage}
              onChange={onChange}
              id="AddEmailConnection"
              label=""
              className={classes.emailTextField}
              value={inputEmailAddress}
              inputProps={{
                maxLength: 254, // RFC 5321 standard
              }}
            />
            {formErrorMessage && <Typography color="error">{t(formErrorMessage)}</Typography>}
          </div>
          <br />
          <div className={classes.expirationDate}>
            <InputLabel
              htmlFor="AddExpirationDate"
              id="AddExpirationDate"
              className={classes.subtitle}
            >
              {t('Delegated authority valid until date')}
            </InputLabel>
            <TextField
              onChange={(e) => {
                setExpirationDate(e.target.value);
                setDateErrorMessage('');
              }}
              id="AddExpirationDate"
              label=""
              type="date"
              value={inputExpirationDate}
              className={classes.emailTextField}
              error={!!dateErrorMessage}
              inputProps={{
                min: format('yyyy-MM-dd', new Date()),
                max: format('yyyy-MM-dd', new Date(9999, 11)),
              }}
            />
            {dateErrorMessage && <Typography color="error">{t(dateErrorMessage)}</Typography>}
          </div>
        </div>
        <br />
        <div>
          {errorMessage && (
            <Typography color="error">{t(errorMessage, { ns: 'errors' })}</Typography>
          )}
          {connectedErrorMessage && (
            <Typography color="error">{t(connectedErrorMessage)}</Typography>
          )}
          <Button type="submit" variant="contained" color="primary">
            {t('Add')}
          </Button>
        </div>
      </form>
    </>
  );
};
