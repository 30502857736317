import MaterialTable from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableIcons } from '../../../utils/icons';
import { getEmail } from '../../../utils/jwt';
import { getLocalizations } from '../../casesTableConfig';

const getTableConfig = (theme, t, onDelete, myConnections) => {
  const getDateString = (d) => {
    const dateObj = new Date(d);
    if (dateObj.getYear() > 0) {
      return dateObj.toISOString().split('T')[0];
    }
    return '';
  };
  const columns = [
    {
      title: t('Email'),
      field: myConnections ? 'ConnectedEmail' : 'Email',
    },
    {
      title: t('Expiration date'),
      field: 'ExpirationDate',
      render: (row) => <span>{getDateString(row.ExpirationDate)}</span>,
    },
  ];
  const options = {
    actionsColumnIndex: -1,
    cellStyle: {
      fontSize: '0.9rem',
    },
    headerStyle: {
      fontSize: '0.9rem',
      backgroundColor: theme.palette.action.disabledBackground,
    },
    searchFieldStyle: {
      fontSize: '0.9rem',
    },
    pageSize: 5,
    emptyRowsWhenPaging: false,
    thirdSortClick: false,
  };
  const actions = [
    {
      icon: DeleteOutline,
      tooltip: t('Delete Connection'),
      onClick: (event, rowData) => onDelete(rowData),
    },
  ];
  return { columns, options, actions };
};

const useStyles = makeStyles((theme) => ({
  titleLarge: {
    [theme.breakpoints.down('xs')]: { display: 'none' },
  },
  titleMobile: {
    display: 'none',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: { display: 'inline-block' },
  },
}));

export const ConnectionsTable = ({ connections, myConnections = false, onDelete }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [dialog, setDialog] = useState(null);
  const classes = useStyles();

  const openDialog = (data) => setDialog(data);
  const closeDialog = () => setDialog(null);
  const email = getEmail();
  const rowData = useMemo(
    () =>
      connections.filter((con) => email.includes(myConnections ? con.Email : con.ConnectedEmail)),
    [connections, myConnections, email],
  );

  const { columns, options, actions } = getTableConfig(theme, t, openDialog, myConnections);
  const onConfirm = () => {
    onDelete(dialog);
    closeDialog();
  };
  const localization = useMemo(() => getLocalizations(t), [t]);

  return (
    <>
      <Dialog
        open={!!dialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('Remove delegation')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t(
              'The person with this email-address will no longer have your permission as administrator',
            )}
            :
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {dialog && (myConnections ? dialog.ConnectedEmail : dialog.Email)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            {t('Cancel')}
          </Button>
          <Button onClick={onConfirm} color="secondary" autoFocus variant="contained">
            {t('Remove delegation')}
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h6" className={classes.titleMobile}>
        {myConnections
          ? t('My authority as administrator delegated to')
          : t('Authority as administrator delegated to me')}
      </Typography>
      <MaterialTable
        title={
          <Typography variant="subtitle1" className={classes.titleLarge}>
            {myConnections
              ? t('My authority as administrator delegated to')
              : t('Authority as administrator delegated to me')}
          </Typography>
        }
        icons={tableIcons}
        columns={columns}
        data={rowData}
        actions={actions}
        options={options}
        localization={localization}
      />
    </>
  );
};
