import React, { useContext } from 'react';
import { Divider, Drawer, Hidden, Box, List } from '@material-ui/core';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { drawerWidth, drawerBreakpoint, menuItems } from '../app.config';
import { DrawerContext } from '../store/appContext';
import { ListItemLink } from './ListItemLink';
import { UserConnectedEmails } from './UserConnectedEmails';
import { isSender } from '../utils/jwt';
import { MainLogo } from './MainLogo';
import { LogoutButton } from './LogoutButton';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    flex: '0 0 auto',
    [theme.breakpoints.up(drawerBreakpoint)]: {
      width: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerParent: {
    marginLeft: '10px',
    borderLeft: '1px solid',
  },
  menuButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}));

export const shouldBeVisible = (title, features) => {
  const restricted = ['Create request', 'QuickSign'];
  const quickSign = features && 'quickSign' in features ? features.quickSign : null;

  if (restricted.includes(title) && !isSender()) {
    // User doesn't have access to these functions
    return false;
  }

  if (title === 'QuickSign' && !quickSign) {
    return false;
  }

  // User has access
  return true;
};

export const AppDrawer = () => {
  const classes = useStyles();
  const { features } = useTheme();
  const { drawerOpen, setDrawer } = useContext(DrawerContext);

  const drawer = (
    <div>
      <Box
        className={classes.toolbar}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
      >
        <MainLogo />
      </Box>
      <Divider />
      <List>
        {menuItems.map(
          (item) =>
            shouldBeVisible(item.primary, features) && <ListItemLink {...item} key={item?.to} />,
        )}
      </List>
      <Divider />
      <UserConnectedEmails variant="caption" labelAsTooltip />
    </div>
  );
  const menuButtons = (
    <div className={classes.menuButtonContainer}>
      <LogoutButton variant="drawer" />
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="app menu">
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={() => void setDrawer(false)}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
          <Hidden smUp>{menuButtons}</Hidden>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};
