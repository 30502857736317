export const personalNumberRegex = /^[0-9]{8}[-]?[0-9]{4}$/;

// The format differs a bit between countries, so does the length.
// There doesn't seem to exist a specification regarding this, other than the general
// format of "NL/SE/2frf24m.....", which says this is a dutch citizen logged in to
// a swedish web service, followed by a unique identifier.
export const eIDASPersonIdRegex = /^[A-Z]{2,3}[\w/\\]{5,100}$/;

export const mobileNumberRegex = /\+(?:[0-9]){6,14}[0-9]/;

// export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const employeeNumberRegex = /^\d+$/;

export const orgAffiliationRegex = /.+@\d{10}$/;

// Example: SE2321000016-1003
// HSA-id byggs upp av landskoden (SE), organisationsnummer för direktanslutna
// producenten, 10 tecken utan bindestreck, (t.ex. landstingets organisationsnummer, 2321000131),
// bindestreck “-”  och därefter <valfria bokstäver (A-Z och a-z) och/eller siffror (0-9)>.
// Observera att tecknen å, ä, ö samt @-tecken inte är tillåtna.
// HSA-id får inte överskrida 31 tecken totalt.
export const hsaIdRegex = /[A-Z]{2}\d{10}-\w+/;
