import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip, List, ListItem } from '@material-ui/core';

import { OBJ } from '../utils/data-utils';
import { getEmail } from '../utils/jwt';
import { ResponsiveUrlOrEmail } from './ResponsiveUrlOrEmail';

// const useStyles = makeStyles(() => ({
//   breaker: {
//     wordBreak: 'break-all',
//   },
// }));

export const UserConnectedEmails = ({
  variant = 'body1',
  labelAsTooltip = false,
  emailProps = OBJ,
  listItemProps = OBJ,
  compact = false,
}) => {
  const { t } = useTranslation();
  // const classes = useStyles();

  const content = (
    <List dense>
      {getEmail().map((email) => (
        <ListItem key={email} {...listItemProps}>
          <Typography variant={variant} {...emailProps}>
            <ResponsiveUrlOrEmail str={email} />
          </Typography>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {!labelAsTooltip ? (
        <>
          <Typography paragraph={!compact} variant={variant}>
            {`${t('Email addresses connected to your account')}:`}
          </Typography>
          {content}
        </>
      ) : (
        <Tooltip title={t('Email addresses connected to your account')}>{content}</Tooltip>
      )}
      {}
    </>
  );
};
