import * as R from 'ramda';
import jsonReduce from 'json-reduce';
import { Base64 } from 'js-base64';
// import { compareAsc } from 'date-fns/esm//fp';

import { getEmail } from './jwt';
import { getFileName } from './data-utils';
import {
  caseSigningStatus,
  findSignerThatIsMe,
  signersReducer,
  anyFoundIn,
  isBlockedForMeCheck,
  canSignCheck,
  timestampReducer,
  canRevokeCheck,
} from './caseReducers';

const jsonReduceCurried = (reducer, seed) => (data) => jsonReduce(data, reducer, seed);

export const prepareIdps = R.pipe(
  R.prop('identityProviders'),
  R.map((item) => ({ ...item, id: item.displayName.replace(/ /g, '_') })),
  (idps = []) => ({
    signIdps: R.filter(R.prop('enabledForSigning'))(idps),
    authIdps: R.filter(R.propOr(true, 'enabledForAuthentication'))(idps),
  }),
);

const evolveCase = R.pipe(
  jsonReduceCurried(timestampReducer, {}),
  R.evolve({
    DocumentURL: R.ifElse(R.endsWith(';'), R.pipe(R.split(';'), R.init), R.of),
    Author: R.evolve({ Email: R.split(';') }),
    Signers: signersReducer,
  }),
);

/**
 * Here is mainly where all derived values for a sign case is built and added.
 * This is to make THE SAME "smart values" available in all pages and components.
 * @param {Object} [signCase={}] A sign case.
 * @returns {[type]} Enhanced sign case.
 */
const enhanceCase = (signCase = {}) => {
  const {
    DocumentURLs = [],
    Signers = [],
    Author: { FirstName = '', LastName = '', Email = [] },
    Revoked,
    ReminderMessageB64,
  } = signCase;

  const status = caseSigningStatus(signCase);
  const mapIndexed = R.addIndex(R.map);
  const files = mapIndexed(
    (path, fileId) => ({
      name: getFileName(path),
      path: `/api/documentservice/guard/documents/${signCase.ID}/files/${fileId}`,
    }),
    DocumentURLs,
  );

  const [signerThatIsMe, indexOfMe] = findSignerThatIsMe(signCase);
  const isBlockedForMe = isBlockedForMeCheck({ ...signCase, indexOfMe });
  const ownerIsMe = anyFoundIn(getEmail())(Email);

  const canSign = canSignCheck({ Revoked, status, signerThatIsMe, indexOfMe, isBlockedForMe });
  const canRevoke = canRevokeCheck({ status, Revoked, signerThatIsMe, ownerIsMe });
  const canSendMsg = true;
  const ReminderMessage = ReminderMessageB64
    ? Base64.decode(ReminderMessageB64)
    : signCase.ReminderMessage ?? '';

  const signersEmailsAccumulated = R.pipe(R.map(R.propOr([], 'Email')), R.flatten)(Signers);
  const signersNamesAccumulated = R.pipe(R.map(R.propOr([], 'Name')), R.flatten)(Signers);
  const personalNumberAccumulated = R.pipe(R.map(R.propOr([], 'PersonalID')), R.flatten)(Signers);

  return {
    ...signCase,
    ReminderMessage, // Override
    signersEmailsAccumulated, // for hidden search field in table
    signersNamesAccumulated,
    personalNumberAccumulated,
    status,
    canSign,
    canRevoke,
    canSendMsg,
    isBlockedForMe,
    signerThatIsMe,
    ownerIsMe,
    owner: FirstName || LastName ? `${FirstName} ${LastName}`.trim() : Email.join(' / '),
    files,
    fileList: R.pipe(R.pluck('name'), R.sortBy(R.identity))(files),
  };
};

export const prepareCases = R.map(R.pipe(evolveCase, enhanceCase));

export const formatCases = (t) =>
  R.map((signCase = {}) => ({
    ...signCase,
    formattedStatus: t(signCase.status),
  }));

// export const sortCases = R.sort((a, b) => compareAsc(a.CreatedAt)(b.CreatedAt));

export const filterCases = (cases, filters) =>
  cases.filter((signRequest) =>
    Object.entries(filters).every(([key, func]) => func(signRequest[key])),
  );
