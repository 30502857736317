import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources as appTranslation } from './translations';
import { errorResources } from './errorTranslations';

const getLanguageResources = (lan1, lan2) => {
  const newObj = {};
  Object.keys(lan1).forEach((language) => {
    newObj[language] = { ...lan1[language], ...lan2[language] };
  });
  return newObj;
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: getLanguageResources(appTranslation, errorResources),
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations', 'errors'],
    defaultNS: 'translations',
    //
    // keySeparator: false, // we use content as keys

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false, // react escapes by default
    },
  });
