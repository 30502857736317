import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  addAuthorConnection,
  deleteAuthorConnection,
  getAuthorConnections,
} from '../../../store/appAPI';
import { AddConnection } from './AddConnection';
import { ConnectionsTable } from './ConnectionsTable';

const useStyles = makeStyles((theme) => ({
  box: { marginBottom: theme.spacing(3) },
  root: {
    width: '100%',
  },
  table: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(6) },
  },
}));

export const DelegateAuthority = () => {
  const classes = useStyles();
  const [connections, setConnections] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const getEmailConnections = async () => {
    const authorConnections = await getAuthorConnections();
    setConnections(authorConnections);
  };

  useEffect(() => {
    getEmailConnections();
  }, []);

  const onEmailSubmit = async (email, expirationDate) => {
    try {
      await addAuthorConnection(email, expirationDate);
    } catch (e) {
      // handle error from backend
      setErrorMessage(e.message);
    }

    getEmailConnections();
  };

  const deleteConnection = async (data) => {
    await deleteAuthorConnection(data.ID);
    getEmailConnections();
  };

  return (
    <div className={classes.root}>
      <Box>
        <AddConnection
          onSubmit={onEmailSubmit}
          errorMessage={errorMessage}
          connections={connections}
        />
      </Box>
      <Box className={classes.table}>
        <ConnectionsTable connections={connections} myConnections onDelete={deleteConnection} />
      </Box>
      <Box className={classes.table}>
        <ConnectionsTable connections={connections} onDelete={deleteConnection} />
      </Box>
    </div>
  );
};
