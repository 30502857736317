import jwtDecode from 'jwt-decode';
import { bi } from 'tri-fp';
import { isAfter, fromUnixTime } from 'date-fns/fp';

import { mocked } from './url';
import { loggedInUser } from '../store/mocks';
import { getUrnoidToPropFunc } from './data-utils';
import { sessionRemove, sessionWrite, sessionRead } from './storage';

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1).toLowerCase();

const safeDecode = bi(jwtDecode);
const safeReadJwtKey = (key, fallback = '') => {
  const [err, result] = safeDecode(sessionRead('access_token'));
  return err ? fallback : key === 'email' ? result[key].split(';') : result[key];
};

export const getJwtEmail = () => safeReadJwtKey('email', ['test@test.test']);
export const getEmail = () => mocked(loggedInUser.email)(getJwtEmail);
export const getPersonalID = () => safeReadJwtKey('personalID', '');
export const isSender = () => safeReadJwtKey('role-sender', false);
export const getName = () => safeReadJwtKey('name', 'Test Name');
export const userHasEmail = () => getEmail().some((email) => email !== '');
export const getLoginIdp = () => safeReadJwtKey('loginIdp', '');
export const getFullName = () => {
  const name = safeReadJwtKey('name', '');
  if (name && name.length) {
    return name;
  }

  const firstName = safeReadJwtKey('firstName', '');
  const lastName = safeReadJwtKey('lastName', '');

  if (firstName && firstName.length && lastName && lastName.length) {
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  }
  return '';
};
export const getOrganization = () => safeReadJwtKey('organization', '');
export const getTitle = () => safeReadJwtKey('title', '');

export const getExpiresAt = () => {
  const exp = Number(safeReadJwtKey('exp', 0));
  const fromUnix = fromUnixTime(exp);
  return fromUnix;
};

export const isExpired = () => isAfter(getExpiresAt())(new Date());

export const writeCookie = (name, value) => {
  document.cookie = `${name}=${value};expires=Session;SameSite=Strict;path=/`;
};

export const getIdentityFromIdps = (idps) => {
  const returnObj = {};

  idps.forEach((element) => {
    if (element.requiredFields && element.requiredFields.length) {
      element.requiredFields.forEach((requiredField) => {
        const method = getUrnoidToPropFunc(requiredField);
        const { id, tokenAttribute } = method(() => '');
        const safeRead = safeReadJwtKey(tokenAttribute, '');
        returnObj[id] = safeRead;
      });
    }
  });
  return returnObj;
};

export const writeToken = (response) => {
  sessionWrite('access_token', response.access_token);
  sessionWrite('refresh_token', response.refresh_token);
  sessionWrite('email-verification-idp-entity-id', response['email-verification-idp-entity-id']);
  sessionWrite('email-verification-optional', response['email-verification-optional']);
  if (!userHasEmail()) {
    sessionWrite('verified_email', false);
  }

  // Temporary workaround to be able to access the document, the
  // DocumentService looks for the signport-token cookie in the browser.
  writeCookie('signport-token', `bearer ${response.access_token}`);
  return response;
};

export const clearTokens = () => {
  sessionRemove('access_token');
  sessionRemove('refresh_token');
  sessionRemove('email_verification_idp_entity_id');
  sessionRemove('email-verification-optional');
};
