import { createContext, useState, useEffect, useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import createPersistedState from 'use-persisted-state';
import { createLocalStorageStateHook } from 'use-local-storage-state';

export const DrawerContext = createContext();

export const useDrawerState = () => {
  const [drawerOpen, setDrawer] = useState(false);
  return { drawerOpen, setDrawer };
};

// const useStoredDark = createPersistedState('darkmode');
const useStoredDark = createLocalStorageStateHook('darkmode', false);
export const DarkContext = createContext();

export const useDarkState = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDark, setDark] = useStoredDark();
  const wasUnset = useRef(isDark == null);

  useEffect(() => {
    if (wasUnset.current) {
      setDark(prefersDarkMode);
    }
  }, [prefersDarkMode, setDark]);

  return { isDark, setDark };
};

const host = window.location.hostname;

export const SkinContext = createContext({
  path: `${host}`,
  setPath: () => {},
});

const useStoredSkin = createLocalStorageStateHook('mockHostName', `${host}`);
export const useSkinState = () => {
  const [path, setPath] = useStoredSkin();
  return { path, setPath };
};
