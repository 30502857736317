import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import LanguageIcon from '@material-ui/icons/Language';
import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useDarkState } from '../../../store/appContext';
import { LangMenuSelect } from '../../LangMenuSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  inputLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '30em',
    maxWidth: 'calc(100% - 2em);',
    marginBottom: theme.spacing(1),
  },
  logolabel: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '1em',
    [theme.breakpoints.down('xs')]: { marginRight: '0.5em' },
  },
}));

export const PageSettings = () => {
  const { t } = useTranslation();
  const { features } = useTheme();
  const { isDark, setDark } = useDarkState();
  const [darkMode, setDarkMode] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (!darkMode) setDark(false);
  }, [darkMode]);

  useEffect(() => {
    if (features) setDarkMode(features && features.darkMode);
  }, [features]);

  return (
    <FormGroup className={classes.root}>
      {darkMode ? (
        <FormControlLabel
          control={
            <Switch
              checked={isDark}
              onChange={(e, val) => {
                setDark(val);
              }}
            />
          }
          label={
            <>
              <span className={classes.logolabel}>
                <Brightness6Icon className={classes.icon} />
                {t('Dark theme')}
              </span>
            </>
          }
          labelPlacement="start"
          className={classes.inputLabel}
        />
      ) : null}
      <FormControlLabel
        control={<LangMenuSelect />}
        label={
          <>
            <span className={classes.logolabel}>
              <LanguageIcon className={classes.icon} />
              {t('Language')}
            </span>
          </>
        }
        labelPlacement="start"
        className={classes.inputLabel}
        style={{ cursor: 'default' }}
      />
    </FormGroup>
  );
};
