import * as R from 'ramda';

// General authorization error, used if no details are given
// export const INVALID_GRANT = 'invalid_grant';
// export const NO_PERMISSIONS_TO_DOCUMENT = 'no permissions to document';
// export const TOKEN_IS_EXPIRED = 'token is expired';
// export const TOKEN_IS_MISSING = 'token is missing';
// export const UNAUTHORIZED = 'unauthorized';
// export const COULD_NOT_GET_TOKEN = 'could not get token';
// export const COULD_NOT_REFRESH_TOKEN = 'could not refresh token';

export const fetchCheckedJson = async (url, { refreshTokenFunc, ...options } = {}) => {
  // eslint-disable-next-line fp/no-mutation
  const res = await fetch(url, options);
  const { ok, status, statusText } = res;
  const json = res.json ? await res.json() : {};
  if (!ok) {
    if (status === 401 && refreshTokenFunc) {
      //  && json.error === TOKEN_IS_EXPIRED
      return refreshTokenFunc().then(() => fetchCheckedJson(url, options));
    }
    let errorMsg = '';
    if (!statusText || statusText.length === 0) {
      if (json && json.error) {
        errorMsg = json.error;
      }
    } else {
      errorMsg = statusText;
    }

    const error = Error(`${errorMsg}`);
    error.response = { status, errorMsg };
    return Promise.reject(error);
  }
  return json;
};

export const urlParams = (obj) =>
  `?${Object.entries(obj)
    .map((pair) => pair.join('='))
    .join('&')}`;

export const getHost = () => window.location.host;
export const getProtocol = () => window.location.protocol;
export const isLocalhost = () => getHost().startsWith('localhost');

export const appUrl = (relativePath, params) =>
  `${getProtocol()}//${getHost()}${relativePath}${params ? urlParams(params) : ''}`;

export const fromParams = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const sleep = (ms, val) => new Promise((r) => setTimeout(() => r(val), ms));

export const mocked = (mock) => (fn) =>
  isLocalhost() ? (R.is(Function)(mock) ? mock() : mock) : fn();

export const mockedAsync = async (mock, fnP) =>
  isLocalhost() ? sleep(1000).then(R.is(Function)(mock) ? mock : () => mock) : fnP();

export const hiddenFormSubmitter = (attr = {}, props = {}) => {
  const form = document.createElement('form');
  Object.entries({ method: 'POST', ...attr }).forEach((pair) => form.setAttribute(...pair));
  Object.entries(props).forEach(([name, value]) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', value);
    form.appendChild(input);
  });
  document.body.appendChild(form);
  form.submit();
};

export const createFormData = (props = {}) => {
  const data = new FormData();
  Object.entries(props).forEach(([key, val]) => data.append(key, val));
  return data;
};
