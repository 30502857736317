import React, { useContext, useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Tooltip, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { UserConnectedEmails } from './UserConnectedEmails';
import { DarkContext, SkinContext } from '../store/appContext';

export const ProfileMenu = ({ moreIcon, noLogout, className }) => {
  const { t } = useTranslation();
  const [dropdownAnchor, setAnchorEl] = React.useState();
  const theme = useTheme();
  const profileDropdownOpen = Boolean(dropdownAnchor);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl();

  const { setDark } = useContext(DarkContext);
  const toggleThemeType = () => {
    setDark((x) => !x);
    handleClose();
  };

  const { features } = theme;

  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    if (!darkMode) setDark(() => false);
  }, [darkMode]);

  useEffect(() => {
    if (features) setDarkMode(features.darkMode);
  }, [features]);

  const { setPath } = useContext(SkinContext);
  const toggleSkins = (newPath) => {
    setPath(newPath);
    handleClose();
  };

  return (
    <>
      {!noLogout || darkMode || !!theme.skinOptions ? (
        <div className={className}>
          <Tooltip title={t('Profile')}>
            <IconButton
              aria-label={t('Profile')}
              aria-controls="appbar-menu-profile"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {moreIcon ? <MoreVertIcon /> : <AccountCircle />}
            </IconButton>
          </Tooltip>
          <Menu
            id="appbar-menu-profile"
            anchorEl={dropdownAnchor}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={profileDropdownOpen}
            onClose={handleClose}
          >
            {theme && theme.skinOptions
              ? theme.skinOptions.map((item) => (
                  <MenuItem onClick={() => toggleSkins(item.hostname)} key={item.title}>
                    {item.title}
                  </MenuItem>
                ))
              : null}
            {darkMode ? (
              <MenuItem onClick={toggleThemeType}>{t('Light/Dark theme')}</MenuItem>
            ) : null}
            {!noLogout && <Divider />}
            {!noLogout && <UserConnectedEmails variant="caption" labelAsTooltip />}
          </Menu>
        </div>
      ) : null}
    </>
  );
};
