import React, { useEffect } from 'react';

import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(2) },
  },
  title: {
    textAlign: 'center',
    marginTop: '0px',
  },
  largeIcon: {
    fontSize: '7em',
    width: '100%',
  },
  responseText: {
    textAlign: 'center',
  },
}));

export const ErrorPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation(['translations', 'errors']);
  const params = useParams();
  const errorCode = params && params.errorCode ? params.errorCode : null;
  const errorMessage = params && params.errorMsg ? params.errorMsg : null;

  useEffect(() => {
    if (errorCode === 'EmployeeAccessAsHandlerPermissionDenied' && errorMessage)
      history.push(`/showError/${errorMessage}`);
  }, []);

  return (
    <div className={classes.root}>
      <SentimentDissatisfiedIcon className={classes.largeIcon} />
      <h2 className={classes.title}>{t('something went wrong')}</h2>
      {errorMessage ? (
        <p className={classes.responseText}>
          {t('error message')}
          {t(errorMessage.toLowerCase(), { ns: 'errors' })}
        </p>
      ) : null}
      {errorCode && errorCode !== '' ? (
        <p className={classes.responseText}>
          {t('error code')} {errorCode}
        </p>
      ) : null}
    </div>
  );
};
