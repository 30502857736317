import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { join, map, pipe, path } from 'ramda';

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(3), maxWidth: 640 },
  pointer: { cursor: 'pointer' },
  highlight: { color: theme.palette.primary.main },
  underline: { textDecoration: 'underline' },
  zone: {
    minHeight: 130,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '3px dashed',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
  },
  accepted: { borderColor: theme.palette.success.main },
  rejected: { borderColor: theme.palette.error.main },
}));

const fileAcceptanceProps = {
  accept: 'application/pdf,application/xml',
  maxSize: '100000000',
};
const filesToNamesStr = pipe(map(path(['file', 'name'])), join(', '));
export const Dropzone = ({
  render = Function.prototype,
  name = '',
  register,
  single = false,
  disabled = false,
}) => {
  const [showClickAlert, setShowClickAlert] = useState(false); // This is a crazy stunt for such little gain - will get rid of this ;-
  const classes = useStyles();
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) =>
      render({
        acceptedFiles,
        namesRejectedFiles: filesToNamesStr(rejectedFiles),
      }),
    [render],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, inputRef } = useDropzone({
    onDrop,
    ...fileAcceptanceProps,
    disabled,
  });

  useEffect(() => {
    if (register) register({ ref: inputRef, name });
  }, [inputRef, name, register]);

  return (
    <div
      onMouseEnter={() => setShowClickAlert(true)}
      onMouseLeave={() => setShowClickAlert(false)}
      className={clsx(classes.root, showClickAlert && classes.pointer)}
    >
      <div
        {...getRootProps({
          className: clsx([
            classes.zone,
            isDragAccept && classes.accepted,
            isDragReject && classes.rejected,
          ]),
        })}
      >
        <input {...getInputProps()} />
        <Typography variant="body2" align="center" style={{ padding: '0 0.6rem' }}>
          {t('Drop document here or', { count: single ? 1 : 2 })}&nbsp;
          <span className={clsx(classes.highlight, showClickAlert && classes.underline)}>
            {t('select a file', { count: single ? 1 : 2 })}
          </span>
        </Typography>
        <Typography variant="body2" align="center">
          (PDF, max 100MB)
        </Typography>
      </div>
    </div>
  );
};
