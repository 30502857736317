import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Box, Typography } from '@material-ui/core';
import { maintenanceDate } from '../utils/maintenanceDate';

const useStyles = makeStyles(() => ({
  fixedPosition: {
    position: 'fixed',
    top: 0,
    left: 0,
  },
  informationBanner: {
    paddingLeft: '5px',
    paddingRight: '5px',
    width: '100%',
    background: 'orange',
    zIndex: '2000',
  },
}));

export const InformationBanner = ({ fixedPosition = false, validationLink = true }) => {
  const url = window.location.origin;
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const isValidationPage = location?.pathname === '/validate';
  return theme?.features?.informationBannerActive ? (
    <Box className={`${fixedPosition && classes.fixedPosition} ${classes.informationBanner}`}>
      {!isValidationPage ? (
        validationLink ? (
          <Typography>
            {t('We are moving to a new operating environment', { maintenanceDate, url })}{' '}
            <Link href={`${url}/validate?certificateInstructions`} variant="subtitle1">
              {t('here')}
            </Link>{' '}
            {t('Instructions adobe')}
          </Typography>
        ) : (
          <Typography>
            {t('We are moving to a new operating environment no url', { maintenanceDate })}
          </Typography>
        )
      ) : (
        <Typography>{t('Validation page instructions', { maintenanceDate, url })}</Typography>
      )}
    </Box>
  ) : null;
};
