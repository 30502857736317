import React from 'react';
import AddCircle from '@material-ui/icons/AddCircle';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Settings from '@material-ui/icons/Settings';

export const drawerWidth = 240;
export const drawerBreakpoint = 'lg';

const items = [
  { primary: 'Sign requests', to: '/', icon: <LibraryBooks /> },
  { primary: 'Create request', to: '/create', icon: <AddCircle /> },
  { primary: 'QuickSign', to: '/quicksign', icon: <FingerprintIcon /> },
  { primary: 'Validate', to: '/validate', icon: <DoneAllIcon /> },
  {
    primary: 'Settings',
    icon: <Settings />,
    to: '/settings',
  },
];

export const pageTitles = [
  {
    primary: 'Change sign request',
    to: '/change/:id',
  },
];

export const menuItems = items;
