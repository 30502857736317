export const langNames = {
  'en-GB': 'English (UK)',
  da: 'Dansk',
  en: 'English (US)',
  et: 'Eesti',
  fi: 'Suomi',
  fr: 'Français',
  de: 'Deutsch',
  no: 'Norsk',
  pl: 'Polski',
  es: 'Español',
  sv: 'Svenska',
};
