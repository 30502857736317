import config from '../config/config.json';
import defaultConfig from '../config/defaultConfig.json';

const host = window.location.hostname;
const hostConfig = config[host];

export const theme = {
  ...defaultConfig,
  ...hostConfig,
  palette: {
    ...defaultConfig.commonPalette,
    ...defaultConfig.palette,
    ...(hostConfig && hostConfig.commonPalette ? hostConfig.commonPalette : {}),
    ...(hostConfig && hostConfig.palette ? hostConfig.palette : {}),
  },
  darkPalette: {
    ...defaultConfig.commonPalette,
    ...defaultConfig.darkPalette,
    ...(hostConfig && hostConfig.commonPalette ? hostConfig.commonPalette : {}),
    ...(hostConfig && hostConfig.darkPalette ? hostConfig.darkPalette : {}),
  },
  features: {
    ...defaultConfig.features,
    ...(hostConfig ? hostConfig.features : {}),
  },
};
