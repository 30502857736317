export const errorResources = {
  en: {
    errors: {
      'Unable to read Organization configuration': 'Unable to read Organization configuration',
      'Could not find session': 'Could not find session',
      'Call to SupportService failed': 'Call to SupportService failed',
      'Response from SupportService indicates an error':
        'Response from SupportService indicates an error',
      'Document could not be fetched from database':
        'Sign request could not be fetched from database',
      'Unable to decode the signed document': 'Unable to decode the signed sign request',
      'Failed to store the signed document': 'Failed to store the signed sign request',
      'File could not be fetched from database': 'File could not be fetched from database',
      'File could not be updated in database': 'File could not be updated in database',
      'Param not valid': 'Param not valid',
      'Document not found': 'Sign request was not found',
      'Document has expired': 'Sign request has expired',
      'Document is revoked': 'Sign request is revoked',
      'Sign request already rejected or revoked': 'Sign request already rejected or revoked',
      'Sign request already deleted': 'Sign request already deleted',
      'You are not a signer of this document': 'You are not a signer of this sign request',
      'No permissions to document': 'No permissions to sign request',
      'Unable to find Signer of Document': 'Unable to find Signer of sign request',
      'Identity Provider not authorized': 'Identity Provider not authorized',
      'You have already signed this document': 'You have already signed this sign request',
      'Waiting for another Signer': 'Waiting for another Signer',
      'Document already in use':
        'The sign request is currently being signed by another signer. Please try again later.',
      'Unable to find Identity Provider in Organization configuration':
        'Unable to find Identity Provider in Organization configuration',
      'Unable to fetch raw document data from storage':
        'Unable to fetch raw sign request data from storage',
      'you do not have permission to use this service as an administrator':
        'Unfortunately, you do not have permission to use this service as an administrator. Contact your administrator to request permission.',
      'an error occurred during the login process':
        'An error occurred during the login process, please contact the system administrator for more information',
      'An unexpected error occurred': 'An unexpected error occurred',
      'Required information was not provided': 'Required information was not provided',
      'The user was not authorized to make the request':
        'The user was not authorized to make the request',
      'Could not save updated document': 'Could not save updated sign request',
      'Could not convert PDF to PDF/A-1b': 'Could not convert PDF to PDF/A-1b',
      'Submitted document has invalid content type':
        'Submitted sign request has invalid content type',
      'Not author of document': 'You are not an administrator for this sign request',
      'The expiration date can not be more than 60 days from today':
        'The expiration date can not be more than 60 days from today',
      'The expiration date must be from today': 'The expiration date must be from today',
      'Signed by all parties': 'Sign request already signed by all parties',
      'Admin does not exist': 'The person is not an administrator for this service',
    },
  },
  sv: {
    errors: {
      'Unable to read Organization configuration': 'Kunde inte läsa organisationens konfiguration',
      'Could not find session': 'Kunde inte hitta sessionen',
      'Call to SupportService failed': 'Förfrågan till SupportService misslyckades',
      'response from supportservice indicates an error':
        'Svaret från SupportService indikerar att ett fel har inträffat',
      'Document could not be fetched from database':
        'Underskriftsärendet kunde inte hämtas från databasen',
      'Unable to decode the signed document': 'Kunde inte avkoda det signerade underskriftsärendet',
      'Failed to store the signed document': 'Kunde unte spara det signerade underskriftsärendet',
      'File could not be fetched from database': 'Fil kunde inte hämtas från databasen',
      'File could not be updated in database': 'Fil kunde inte uppdateras i databasen',
      'Param not valid': 'Parameter inte tillåten',
      'Document not found': 'Underskriftsärendet hittades inte',
      'Document has expired': 'Underskriftsärendet har gått ut',
      'Document is revoked': 'Underskriftsuppdraget har återkallats',
      'Sign request already rejected or revoked':
        'Underskriftsuppdraget har redan återkallats eller avvisats',
      'Sign request already deleted': 'Underskriftsuppdraget har redan tagits bort',
      'You are not a signer of this document':
        'Du är inte en undertecknare av detta underskriftsärende',
      'No permissions to document': 'Det saknas behörighet till underskriftsärendet',
      'Unable to find Signer of Document':
        'Kunde inte hitta undertecknare till underskriftsärendet',
      'Identity Provider not authorized': 'Identitetsleverantören är inte auktoriserad',
      'You have already signed this document':
        'Du har redan skrivit under detta underskriftsärende',
      'Waiting for another Signer': 'Väntar på en annan undertecknare',
      'Document already in use':
        'En annan undertecknare håller på att skriva under. Vänta en stund och försök igen.',
      'Unable to find Identity Provider in Organization configuration':
        'Det gick inte att hitta identitetsleverantören i organisationskonfigurationen',
      'Unable to fetch raw document data from storage':
        'Det gick inte att hämta underskriftsärendets rådata',
      'you do not have permission to use this service as an administrator':
        'Du saknar tyvärr behörighet att använda den här tjänsten som Handläggare. Kontakta din administratör för att begära behörigheten.',
      'an error occurred during the login process':
        'Ett fel inträffade vid inloggningen, vänligen kontakta systemadministratör för mer information',
      'An unexpected error occurred': 'Ett oväntat fel inträffade',
      'Required information was not provided': 'Nödvändig information saknas i anropet',
      'The user was not authorized to make the request':
        'Användaren är ej behörig att göra begäran',
      'Could not save updated document': 'Underskriftsärendeändringarna kunde inte sparas',
      'Could not convert PDF to PDF/A-1b': 'Konvertering av PDF till PDF/A-1b misslyckades',
      'Submitted document has invalid content type': 'En inskickad PDF-fil har felaktigt filformat',
      'Not author of document': 'Du är ej handläggare för detta underskriftsärende',
      'The expiration date can not be more than 60 days from today':
        'Utgångsdatumet får inte vara mer än 60 dagar from idag',
      'The expiration date must be from today': 'Utgångsdatumet måste vara från och med idag',
      'Signed by all parties': 'Underskriftsuppdraget är redan underskrivet av alla parter',
      'Admin does not exist': 'Personen finns inte som handläggare i tjänsten',
    },
  },
};
