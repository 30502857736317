import React, { useState, useEffect } from 'react';

export const Timer = ({ minutes = 1, onTimeout }) => {
  const [minutesLeft, setMinutes] = useState(minutes);
  const [secondsLeft, setSeconds] = useState(0);

  const [targetDate, setTargetDate] = useState(null);

  useEffect(() => {
    if (!targetDate) {
      const currentDate = new Date();
      // It will wrap hours, days, years etc correctly
      currentDate.setMinutes(currentDate.getMinutes() + minutes);
      setTargetDate(currentDate);
    }
    const timeIntervall = setInterval(() => {
      const currentDate = new Date();
      // const targetDate = new Date(toDate);
      if (currentDate >= targetDate) {
        clearInterval(timeIntervall);
        onTimeout();
      } else {
        const targetDateMinutes = targetDate.getMinutes();
        let currentMinutes = currentDate.getMinutes();
        const targetDateSeconds = targetDate.getSeconds();
        let currentSeconds = currentDate.getSeconds();
        if (targetDateSeconds >= currentSeconds) {
          setSeconds(targetDateSeconds - currentSeconds);
        } else {
          currentSeconds -= 60;
          currentMinutes += 1;
          setSeconds(targetDateSeconds - currentSeconds);
        }
        if (targetDateMinutes >= currentMinutes) {
          setMinutes(targetDateMinutes - currentMinutes);
        } else {
          const diffMinutes = currentMinutes - 60;
          setMinutes(targetDateMinutes - diffMinutes);
        }
      }
    }, 1000);
    return () => {
      if (timeIntervall) clearInterval(timeIntervall);
    };
  }, [targetDate, minutes, onTimeout]);

  return (
    <span>
      {minutesLeft}:{secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}
    </span>
  );
};
