import React from 'react';
import * as R from 'ramda';

export const ResponsiveUrlOrEmail = R.compose(
  R.addIndex(R.map)((token, ix) => (
    <span key={token}>
      {ix ? (
        <>
          <wbr />
          {token}
        </>
      ) : (
        token
      )}
    </span>
  )),
  R.map(R.join('')),
  R.groupWith((s) => './@'.includes(s[0])),
  R.split(/([./@]+)/g),
  R.prop('str'),
);
