import React, { forwardRef } from 'react';
import { withStyles } from '@material-ui/core';
import { amber, orange, green, grey } from '@material-ui/core/colors';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import AlarmIcon from '@material-ui/icons/Alarm';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import WarningIcon from '@material-ui/icons/Warning';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ErrorIcon from '@material-ui/icons/Error';
import DoneOutlineTwoToneIcon from '@material-ui/icons/DoneOutlineTwoTone';

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const commonStatusStyle = { verticalAlign: 'middle', marginRight: 2 };
// const FileIconAligned = withStyles({ root: { verticalAlign: 'bottom' } })(FileIcon);

const SentIcon = withStyles({ root: { ...commonStatusStyle, color: grey[600] } })(MailOutlineIcon);
const ReadIcon = withStyles({ root: { ...commonStatusStyle, color: grey[600] } })(
  DraftsOutlinedIcon,
);
const SignedIcon = withStyles({ root: { ...commonStatusStyle, color: green[600] } })(
  CheckCircleOutlineIcon,
);
const PartiallySignedIcon = withStyles({ root: { ...commonStatusStyle, color: amber[400] } })(
  EditRoundedIcon,
);
const ExpiredIcon = withStyles({ root: { ...commonStatusStyle, color: orange[900] } })(AlarmIcon);
const RevokedIcon = withStyles({ root: { ...commonStatusStyle } })(CancelRoundedIcon);
const RejectedIcon = withStyles({ root: { ...commonStatusStyle } })(CancelOutlinedIcon);
const UnknownIcon = withStyles({ root: { ...commonStatusStyle } })(HelpOutlineIcon);

export const statusIcons = {
  Signed: (props) => <SignedIcon {...props} />,
  Revoked: (props) => <RevokedIcon {...props} />,
  Rejected: (props) => <RejectedIcon {...props} />,
  Expired: (props) => <ExpiredIcon {...props} />,
  'Partially signed': (props) => <PartiallySignedIcon {...props} />,
  Sent: (props) => <SentIcon {...props} />,
  Read: (props) => <ReadIcon {...props} />,
  Unknown: (props) => <UnknownIcon {...props} />,
};
export const getStatusIcon = (status) => statusIcons[status] ?? statusIcons.Unknown;

export const DocumentViewIcons = {
  zoomInIcon: <ZoomInIcon />,
  zoomOutIcon: <ZoomOutIcon />,
  arrowBackIcon: <ArrowBackIcon />,
};

export const validatorIcons = {
  SUCCESS: DoneOutlineTwoToneIcon,
  FAIL: NotInterestedIcon,
  WARN: WarningIcon,
  ERROR: ErrorIcon,
};
