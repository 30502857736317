import React, { useContext, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AppRouter } from './AppRouter';
import { AppMachineContext } from '../store/appMachine';
import { Timer } from './Timer';
import { write, read, remove, sessionRemove } from '../utils/storage';

const useStyles = makeStyles((theme) => ({
  actions: {
    flexFlow: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
}));

export const IdleHandler = () => {
  const warningMinutes = 2;
  const idleMinutes = 10;
  const minute = 1000 * 60;

  const { t } = useTranslation();
  const [, send] = useContext(AppMachineContext);
  const classes = useStyles();
  const [dialogIsOpen, setIsOpen] = React.useState(false);
  const [timer, setTimer] = useState(false);
  const history = useHistory();

  const openDialog = () => {
    setIsOpen(true);
  };
  const closeDialog = () => {
    setIsOpen(false);
    setTimer(false);
  };

  const handleCloseDialog = () => {
    closeDialog();
    const val = read('closePopUp');
    if (val) remove('closePopUp');
    else write('closePopUp', 'true');
  };

  const onTimeout = () => {
    write('logOut', 'true');
    sessionRemove('toURL');
    send('LOG_OUT');
    history.push('/');
  };

  useIdleTimer({
    timeout: minute * idleMinutes,
    onIdle: () => {
      setTimer(true);
      openDialog();
    },
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  useEffect(() => {
    // Cross-tab communication using local storage.
    window.addEventListener('storage', (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'closePopUp') {
        closeDialog();
      }
    });
    return () => {
      window.removeEventListener('storage', null);
    };
  });

  return (
    <>
      <Dialog
        open={dialogIsOpen}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="alert-dialog-slide-title">{t('are you still there')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('You are not active')}</DialogContentText>
          <DialogContentText>
            {t('time remaining')}
            {timer && <Timer minutes={warningMinutes} onTimeout={onTimeout} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onTimeout} color="secondary" variant="outlined">
            {t('Log out')}
          </Button>
          <Button onClick={handleCloseDialog} color="secondary" variant="contained">
            {t('Im here')}
          </Button>
        </DialogActions>
      </Dialog>
      <AppRouter />
    </>
  );
};
